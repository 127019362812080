import { ISnowWebcam } from 'api/home/types';
import { IResortPathInfo } from 'api/resort/types';
import { CameraList } from 'components/resort/CameraList';
import React from 'react';
import styles from './styles.module.scss';

type HeaderScope = 'h1' | 'h2';

export const SnowWebcams = ({
  snowWebcams,
  header,
  showSnowReport,
  showSummitDepth,
  imageLink,
  titleLink,
  isFresh,
  headerScope = 'h2',
}: {
  headerScope?: HeaderScope;
  isFresh: boolean;
  snowWebcams: Array<ISnowWebcam>,
  header?: string,
  showSummitDepth?: boolean;
  showSnowReport?: boolean;
  imageLink?: (r: IResortPathInfo) => string,
  titleLink?: (r: IResortPathInfo) => string,
}) => {
  const headerTitle = React.createElement(headerScope, { className: styles.title }, header);

  return (
    <section className={styles.snowWebcams}>
      {headerTitle}
      <CameraList
        skipSingleCamera={false}
        isFresh={isFresh}
        imageLink={imageLink}
        titleLink={titleLink}
        showSnowReport={showSnowReport}
        showSummitDepth={showSummitDepth}
        cameras={snowWebcams.map((item) => ({
          ...item.webcam,
          resort: item.resort,
        }))}
      />
    </section>
  );
};
