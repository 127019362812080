import { Hero400Error } from 'components/400';
import { AdUnitContext } from 'googlead/components/AdUnitContext';
import { HeadMeta } from 'components/common/HeadMeta';
import { HeaderNav, HeaderNavCurLink } from 'components/common/HeaderNav';
import { Footer } from 'components/blocks/Footer';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { GetStaticProps, GetStaticPropsResult } from 'next';
import {
  fetchBackgroundImages,
} from 'api/home/static';
import {
  IBackgroundImages,
} from 'api/home/types';
import { AnalyticsContext } from 'util/analytics';
import { Diagnostics } from 'util/diagnostics';

export type Props = {
  backgroundImage: IBackgroundImages;
};

export const DEBUG_TAG = '400-page';
export const TEMPLATE_ID = '1.2';
export const TEMPLATE_NAME = '400 Page';

export default function IntegrityError() {
  const route = useRouter();

  useEffect(() => {
    Diagnostics.error({ tag: DEBUG_TAG, message: 'Page Error', data: { url: global.location?.href, route: route.asPath } });
  }, []);

  return (
    <AnalyticsContext templateId={TEMPLATE_ID} templateName={TEMPLATE_NAME}>
      <AdUnitContext
        adType="other"
        adTKey="error"
      >
        <div style={{ background: '#f4f5f5' }}>
          <HeadMeta
            title="Data Error"
            description="Something went wrong, please contact us with page URL that happened on"
          />
          <HeaderNav current={HeaderNavCurLink.MAIN} />
          <Hero400Error />
          <Footer />
        </div>
      </AdUnitContext>
    </AnalyticsContext>
  );
}

// This function gets called at build time
export const getStaticProps: GetStaticProps<Props> = async () => {
  const backgroundImage = await fetchBackgroundImages();

  const f: GetStaticPropsResult<Props> = {
    props: {
      backgroundImage,
    },
  };
  return f;
};
