import { getLocale } from 'i18n/locale';
import { ELocale } from 'i18n/localeEnum';

export const Skiinfo = 'https://it.newsonthesnow.com/news/wp-content/themes/otstheme/images/default-img-skiinfo.jpg';
export const OTS = 'https://it.newsonthesnow.com/news/wp-content/themes/otstheme/images/default-img.jpg';

export const map: Record<ELocale, string> = {
  [ELocale.EN_US]: OTS,
  [ELocale.DE_DE]: Skiinfo,
  [ELocale.EN_GB]: OTS,
  [ELocale.FR_FR]: Skiinfo,
  [ELocale.IT_IT]: Skiinfo,
  [ELocale.PL_PL]: Skiinfo,
  [ELocale.CS_CZ]: OTS,
  [ELocale.SK_SK]: OTS,
  [ELocale.ES_ES]: '',
  [ELocale.DA_DK]: '',
  [ELocale.NN_NO]: '',
  [ELocale.NL_NL]: '',
  [ELocale.SV_SE]: '',
};

export const newsDefaultImage = () => map[getLocale() as ELocale];
