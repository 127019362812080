import { useMemo } from 'react';
import sanitizeHtml, { IOptions } from 'sanitize-html';

export enum SanitizeMode {
  Strict = 'strict',
  TextOnly = 'text',
  Normal = 'normal',
  Partial = 'partial',
  NormalWithImages = 'normalWithImages',
}

export const options: Record<SanitizeMode, IOptions> = {
  [SanitizeMode.Normal]: {},
  [SanitizeMode.Strict]: {
    allowedTags: ['a'],
    transformTags: {
      a: sanitizeHtml.simpleTransform('a', { rel: 'noopener noreferrer nofollow' }),
    },
  },
  [SanitizeMode.TextOnly]: {
    allowedTags: [],
  },
  [SanitizeMode.Partial]: {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'h2', 'h3', 'h4', 'h5', 'p', 'span'],
    allowedAttributes: {
      a: ['href', 'name', 'target'],
    },
    transformTags: {
      a: sanitizeHtml.simpleTransform('a', { rel: 'noopener noreferrer nofollow' }),
    },
  },
  [SanitizeMode.NormalWithImages]: {
    allowedTags: [
      'img',
      'h3', 'h4',
      'h5', 'h6', 'blockquote', 'dd', 'div',
      'dl', 'dt', 'figcaption', 'figure', 'hr', 'li', 'ol', 'p', 'pre',
      'ul', 'a', 'abbr', 'b', 'bdi', 'bdo', 'br', 'cite', 'code', 'data', 'dfn',
      'em', 'i', 'kbd', 'mark', 'q', 'rb', 'rp', 'rt', 'rtc', 'ruby', 's', 'samp',
      'small', 'span', 'strong', 'sub', 'sup', 'time', 'u', 'var', 'wbr', 'caption',
    ],
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading', 'align'],
    },
    transformTags: {
      a: sanitizeHtml.simpleTransform('a', { rel: 'noopener noreferrer nofollow' }),
    },
  },
};

export const sanitized = (
  s: string,
  mode: SanitizeMode = SanitizeMode.Normal,
  extendedOptions?: IOptions,
) => {
  const clean = sanitizeHtml(
    s,
    {
      ...options[mode],
      ...extendedOptions,
    },
  );
  return { dangerouslySetInnerHTML: { __html: clean } };
};

export const textOnly = (s: string) => sanitizeHtml(s, options[SanitizeMode.TextOnly]);

export const useSanitizedText = (
  rawText: string,
  mode: SanitizeMode = SanitizeMode.Normal,
  extendedOptions?: IOptions,
) => (
  useMemo(
    () => sanitizeHtml(
      rawText,
      {
        ...options[mode],
        ...extendedOptions,
      },
    ),
    [rawText, mode],
  )
);

export const useSanitizedHtmlProps = (s: string, mode: SanitizeMode = SanitizeMode.Normal, extendedOptions?: IOptions) => (
  useMemo(
    () => sanitized(s, mode, extendedOptions),
    [s, mode, extendedOptions],
  )
);
