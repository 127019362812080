import { axiosInstance } from 'api/axiosInstance';
import { staticRoutes } from 'api/apiRoutes';
import {
  IRegionCam,
  IRegionFAQ,
  IRegionFeaturedResort, IRegionResortsNonPaginated,
  IRegionResortsPaginated, IRegionStats,
  IRelatedForRegion,
} from 'api/region/types';
import { INews, IResortTopRated, ResortTopRated } from 'api/home/types';
import {
  IPaginationResponse, IResortPathInfo, OpenFlag, ResortStatus,
} from 'api/resort/types';
import { IGridSorting, SortingType } from 'components/common/Grid/types';
import { ILiftTicketsResponse } from 'components/region/LiftTicketTable/types';
import { IAccommodationPage } from 'api/tripadvisor/types';
import { getPortalOptions } from 'util/getPortalConfig';

/**
 * @deprecated
 */
export const fetchRegionStaticPathInfo = async () => (
  []
  // (await get<IRegionAllPathsInfo>(staticRoutes.regionList())).data
);

export const fetchRegionResortsByStatus = async (
  regionId: string,
  status: OpenFlag | ResortStatus,
  page: number,
  limit: number,
  orderBy?: string,
  direction?: SortingType,
) => (
  await axiosInstance().get<IRegionResortsPaginated>(
    staticRoutes.regionResortsByStatus(regionId, status, page, limit, orderBy, direction),
  )
).data;

export const fetchRegionLiftTicketsPricesByResort = async (
  regionId: string,
  page: number,
  limit: number,
  orderBy?: string,
  direction?: SortingType,
) => (
  await axiosInstance().get<ILiftTicketsResponse>(
    staticRoutes.regionLiftTicketsPricesByResort(regionId, page, limit, orderBy, direction),
  )
).data;

export const fetchAllRegionResortsTopSnow = async (sorting?: IGridSorting) => (
  await axiosInstance().get<IRegionResortsNonPaginated>(staticRoutes.regionAllResortsTopSnow(sorting))
).data;

export const fetchRegionClosingMeta = async (uuid: string) => (
  await axiosInstance().get<{ 'opening': string, 'closing': string }>(staticRoutes.regionClosingOpenDates(uuid))
).data;

export const fetchRegionClosingDate = async (uuid: string) => (await fetchRegionClosingMeta(uuid)).closing;

export const fetchRegionOpeningDate = async (uuid: string) => (await fetchRegionClosingMeta(uuid)).opening;

export const fetchRegionMeta = async (uuid: string) => (
  await axiosInstance().get<{ 'resorts':{ 'active':number } }>(staticRoutes.regionMeta(uuid))
).data;

export const fetchResortMeta = async (uuid: string) => (
  await axiosInstance().get<{ 'snow':{ 'last24':number } }>(staticRoutes.resortMeta(uuid))
).data;

export const fetchRegionRelated = async (regionId: string) => (
  await axiosInstance().get<IRelatedForRegion>(staticRoutes.regionRelated(regionId))
).data;

export const fetchRegionFeaturedCam = async (regionId: string) => (
  await axiosInstance().get<IRegionCam>(staticRoutes.regionFeaturedCam(regionId))
).data;

export const fetchRegionCameras = async (regionId: string) => (
  await axiosInstance().get<{ data: Array<IRegionCam>, pagination: IPaginationResponse }>(staticRoutes.regionCameras(regionId))
).data.data;

export const fetchRegionCamerasByStatus = async (regionId: string, status: OpenFlag | ResortStatus, page: number, limit: number) => (
  await axiosInstance().get<{ data: Array<IRegionCam>, pagination: IPaginationResponse }>(staticRoutes.regionCamerasByStatus(regionId, status, page, limit))
).data;

export const fetchRegionFeatured = async (regionId: string) => (
  await axiosInstance().get<IRegionFeaturedResort>(staticRoutes.regionFeatured(regionId))
).data;

export const fetchRegionResortTopRating = async (uuid: string, type: ResortTopRated) => (
  await axiosInstance(type).get<{ data: Array<IResortTopRated> }>(staticRoutes.regionResortTopRating(uuid, type))
).data.data;

export const fetchRegionResortListGuide = async (regionId: string) => (
  await axiosInstance().get<Array<IResortPathInfo>>(staticRoutes.regionResortListGuide(regionId))
).data;

export const fetchRegionFAQ = async (regionId: string) => (
  await axiosInstance().get<IRegionFAQ>(staticRoutes.regionFAQ(regionId))
).data;

export const fetchRegionStats = async (regionId: string) => (
  await axiosInstance().get<IRegionStats>(staticRoutes.regionStats(regionId))
).data;

export const fetchRegionAccommodations = async (regionId: string) => (
  await axiosInstance().get<IAccommodationPage>(staticRoutes.regionAccommodations(regionId))
).data;

export const fetchRegionRelatedNews = async (regionUUID: string) => {
  const { hasNews } = getPortalOptions();
  if (hasNews) {
    const { data } = await axiosInstance().get<Array<INews>>(staticRoutes.regionRelatedNews(regionUUID));
    return data;
  }
  return null;
};
