import classNames from 'classnames';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { Cell } from 'components/home/PopularSearches/Cell';
import { IPopularSearch } from 'api/home/types';
import { NextLink } from 'components/blocks/NextLink';
import { Routes } from 'routes';
import styles from './styles.module.scss';

export const PopularSearches = ({ popularSearch }: { popularSearch: IPopularSearch }) => {
  const { t } = useOTSTranslation('common');

  return (
    <section className={styles.popularSearches}>
      <span className={classNames('h2', styles.title)}>
        <span>{t('homePage.popularSearches')}</span>
      </span>
      <div className={styles.grid}>
        {
          popularSearch.results.slice(0, 5).map((item) => (
            <Cell
              key={typeof item === 'string' ? item : item.uuid}
              query={item}
            />
          ))
        }
        <>
          <NextLink
            href={Routes.nearbyResorts()}
            type="button"
            className={styles.cell}
          >
            <div className={styles.query}>{t('homePage.nearbyResorts')}</div>
          </NextLink>
        </>
      </div>
    </section>
  );
};
