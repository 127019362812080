import { IWithChildren } from 'types';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const BlockHeader = (
  {
    children,
    headChildren,
    className,
    title,
    useH3,
  }: Partial<IWithChildren> & {
    className?: string,
    title: string,
    useH3?: boolean,
    headChildren?: React.ReactNode
  },
) => (
  <div className={classNames(styles.box, className, !(title || headChildren) && styles.noTitle)}>
    {(title || headChildren) && !useH3 && (
      <h2 className={classNames(styles.title)}>
        {title}
        {headChildren}
      </h2>
    )}
    {(title || headChildren) && useH3 && (
      <h3 className={classNames(styles.title)}>
        {title}
        {headChildren}
      </h3>
    )}
    <div className={styles.links}>
      {children}
    </div>
  </div>
);
