import { IRegionShort } from 'api/region/types';
import { NextLink } from 'components/blocks/NextLink';
import { useCallback, useState } from 'react';
import { SearchPopup } from 'components/home/SearchPopup';
import { Routes } from 'routes';
import { useAnalytics } from 'util/analytics';
import styles from './styles.module.scss';

export const Cell = ({ query }: { query: string | IRegionShort }) => {
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);
  const a = useAnalytics();

  const toggleSearch = useCallback(() => {
    setSearchOpen(!isSearchOpen);
    if (!isSearchOpen) { // Will be open
      a.searchBox();
    }
  }, [isSearchOpen]);

  if (typeof query === 'string') {
    return (
      <>
        {isSearchOpen && (
          <SearchPopup
            closePopup={toggleSearch}
            query={query}
          />
        )}
        <button
          type="button"
          className={styles.cell}
          onClick={toggleSearch}
        >
          <div className={styles.query}>{query}</div>
        </button>
      </>
    );
  }
  return (
    <>
      <NextLink
        href={Routes.regionSnowReport(query)}
        type="button"
        className={styles.cell}
      >
        <div className={styles.query}>{query.title}</div>
      </NextLink>
    </>
  );
};
