import { ParsedUrlQuery } from 'querystring';
import {
  GetServerSidePropsContext, GetServerSidePropsResult, GetStaticPropsContext, GetStaticPropsResult,
} from 'next';
import { DEFAULT_REVALIDATE } from 'ots-constants';
import { isMainLocale } from 'util/i18nFlags';
import { isAxiosError } from 'util/isAxiosError';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'cookies';
import { getLocale } from 'i18n/locale';
import { ELocale } from 'i18n/localeEnum';
import { DateTime } from 'luxon';
import { ONETRUST_COOKIE } from 'onetrust/constants';
import { parseOTSCookieFromString } from 'onetrust/parseOTCookie';

export class NotFoundError extends Error {}

export const assertShouldBeNot404 = async <T>(fn: ()=>Promise<T>) => {
  try {
    return await fn();
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      throw new NotFoundError(e.message);
    }
    throw e;
  }
};

export const wrapStaticProps = <P, Q extends ParsedUrlQuery>(
  fetchProps: (params: Q) => Promise<P>, onlyMain: boolean = false,
) => async (
    context: GetStaticPropsContext<Q>,
  ) => {
    if (onlyMain && !isMainLocale()) {
      return {
        notFound: true,
      } as GetStaticPropsResult<P>;
    }
    try {
      return {
        props: await fetchProps(context.params!),
        revalidate: DEFAULT_REVALIDATE,
      } as GetStaticPropsResult<P>;
    } catch (e) {
      if (e instanceof NotFoundError) {
        return {
          notFound: true,
        } as GetStaticPropsResult<P>;
      }
      throw e;
    }
  };

const SESSION_COOKIE = 'session';

export const wrapServerSideProps = <P, Q extends ParsedUrlQuery>(
  fetchProps: (params: Q) => Promise<P>, onlyMain: boolean = false,
) => async (
    context: GetServerSidePropsContext<Q>,
  ) => {
    const cookies = new Cookies(context.req, context.res);
    const otsCookie = parseOTSCookieFromString(cookies.get(ONETRUST_COOKIE) || '');
    console.log('[otsCookie]', otsCookie.groups);
    if (getLocale() === ELocale.EN_US) {
      const session = cookies.get(SESSION_COOKIE) || `${process.env.NEXT_PUBLIC_AB_VARIATION_NAME}:${uuidv4()}`;
      cookies.set(SESSION_COOKIE, session, {
        expires: DateTime.now().plus({ day: 1 }).toJSDate(),
      });
    }

    if (onlyMain && !isMainLocale()) {
      return {
        notFound: true,
      } as GetServerSidePropsResult<P>;
    }
    try {
      return {
        props: { ...(await fetchProps(context.params!)), OneTrustAllowed: otsCookie.groups },
      } as GetServerSidePropsResult<P>;
    } catch (e) {
      if (e instanceof NotFoundError) {
        return {
          notFound: true,
        } as GetStaticPropsResult<P>;
      }
      throw e;
    }
  };
