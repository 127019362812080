import { IWithChildren } from 'types';
import classNames from 'classnames';
import { ANIMATED_BLUR_CONTENT_STYLE_NAME, BLUR_CONTENT_STYLE_NAME } from 'theme';
import styles from './styles.module.scss';

interface IProps {
  className?: string,
  blur?: boolean,
  isAnimatedBlur?: boolean,
}

export const BlockContent = ({
  children,
  className,
  blur,
  isAnimatedBlur,
}: IWithChildren & IProps) => (
  <div className={classNames(
    styles.box,
    blur ? BLUR_CONTENT_STYLE_NAME : '',
    isAnimatedBlur ? ANIMATED_BLUR_CONTENT_STYLE_NAME : '',
    className,
  )}
  >
    {children}
  </div>
);
