import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { Search } from 'components/home/Search';
import { PopularSearches } from 'components/home/PopularSearches';
import classNames from 'classnames';
import { IBackgroundImages, IPopularSearch } from 'api/home/types';
import styles from './style.module.scss';

export const HomeHero = ({
  backgroundImage,
  popularSearch,
  searchQuery,
  clearSearchQuery,
  loading,
}: {
  backgroundImage: IBackgroundImages,
  popularSearch: IPopularSearch,
  searchQuery: string | string[] | undefined,
  clearSearchQuery?: () => void,
  loading?: boolean,
}) => {
  const { t } = useOTSTranslation('common');

  return (
    <header className={styles.photo} style={!loading ? { backgroundImage: `url("${backgroundImage.image}"` } : {}}>
      <div className={styles.background} />
      {/* <div className={styles.mountains} /> */}
      <div className={classNames('container', styles.container)}>
        <span className={classNames('h1', styles.title)}>{t('homePage.heroTitle')}</span>
        <span className={classNames('h2', styles.subtitle)}>{t('homePage.heroSubtitle')}</span>
        <Search searchQuery={searchQuery} clearSearchQuery={clearSearchQuery} />
        <PopularSearches popularSearch={popularSearch} />
      </div>
    </header>
  );
};
