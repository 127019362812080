import { ICategoryNews, INews } from 'api/home/types';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { NextLink } from 'components/blocks/NextLink';
import classNames from 'classnames';
import React from 'react';
import { Routes } from 'routes';
import { BLUR_CONTENT_STYLE_NAME } from 'theme';
import { News } from './News';
import styles from './styles.module.scss';

export const NewsList = ({
  title,
  news,
  weatherNews,
  areNewsFresh,
}: {
  title?: string,
  news: Array<INews>,
  weatherNews?: boolean,
  areNewsFresh: boolean,
}) => {
  const { t } = useOTSTranslation('common');

  return (
    <section className={classNames(styles.wrapper, 'mt-4')}>
      {title && <h2>{title}</h2>}
      <div className={classNames(styles.wrapperBox, !areNewsFresh && BLUR_CONTENT_STYLE_NAME)}>
        <div>
          {news.map((item: INews | ICategoryNews) => (
            <News
              areNewsFresh={areNewsFresh}
              key={item.uuid}
              news={item}
            />
          ))}
        </div>
      </div>
      <div className={styles.btn}>
        {
          weatherNews && (
            <NextLink href={Routes.weather}>
              {t('snowWeather.home.viewAllWeatherNews')}
            </NextLink>
          )
        }
        {
          title && !weatherNews && (
            <NextLink href={Routes.magazine}>
              {t('common.loadMore')}
            </NextLink>
          )
        }
      </div>
    </section>
  );
};
