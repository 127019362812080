import { ICategoryNews, INews } from 'api/home/types';
import { NextLink } from 'components/blocks/NextLink';
import { useRef } from 'react';
import { newsDefaultImage } from 'util/newsDefaultImage';
import { SanitizeMode, useSanitizedHtmlProps } from 'util/sanitized';
import { useVisibilityWindow } from 'util/useVisibilityWindow';
import styles from './styles.module.scss';

export const News = ({
  news,
  areNewsFresh,
}: {
  news: INews | ICategoryNews,
  areNewsFresh: boolean,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useVisibilityWindow(ref, true);
  const image = (areNewsFresh && isVisible) ? (news.image || newsDefaultImage()) : '';
  const style = image ? { backgroundImage: `url(${image})`, backgroundSize: 'cover' } : { };

  const title = useSanitizedHtmlProps(news.title || '', SanitizeMode.TextOnly);
  const text = useSanitizedHtmlProps(news.description || '', SanitizeMode.TextOnly);

  return (
    <div className={styles.box} ref={ref}>
      <NextLink href={news.link ? news.link : ''}>
        <>
          <figure style={style} tabIndex={-1} />
          <div className={styles.footer}>
            {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
            <span {...title} className={styles.title} />
            <p {...text} />
          </div>
        </>
      </NextLink>
    </div>
  );
};
